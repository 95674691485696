@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: #f8fafb;
  color: #000000;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

a {
  background-color: transparent;
  background-color: transparent;
  text-decoration: none;
  color: #000000;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0 !important;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

img {
  width: 100%;
  height: auto;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}
button:hover {
  transform: scale(1.05);
}

input {
  transition: 0.3s;
  border: none;
  outline: none;
}

.shadow, .tableContainer .zeroResultContainer, .zeroResultContainer {
  box-shadow: 0px 5px 25px 0px rgba(40, 126, 184, 0.15);
}

.zeroResultContainer {
  background-color: #fff;
  max-width: 800px;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
  padding: 10px 25px;
}
.zeroResultContainer h3 {
  color: #000000;
  text-transform: capitalize;
}

.loadingScreen {
  min-height: 60vh;
  display: grid;
  place-items: center;
}

.showmoreContainer {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.6);
}

.showmoreContainer2 {
  font-size: 16px;
  font-weight: 400;
}

.show-more-less-clickable {
  color: #004aad;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 16px;
}

.dropzone {
  width: 100%;
  max-width: 550px;
  border: 2px dashed #004aad;
  border-radius: 15px;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 50px;
  transition: 0.3s;
  cursor: pointer;
}
.dropzone .dropzoneContent {
  display: grid;
  place-items: center;
  width: 100%;
  padding: 60px 20px;
  transition: 0.3s;
}
.dropzone .dropzoneContent p {
  font-size: 16px;
}
.dropzone .dropActive {
  background-color: rgba(122, 172, 252, 0.2);
}
.dropzone:hover {
  background-color: rgba(122, 172, 252, 0.2);
}

.tableContainer {
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}
.tableContainer .zeroResultContainer {
  background-color: #fff;
  max-width: 800px;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
  padding: 10px 25px;
}
.tableContainer .zeroResultContainer h3 {
  color: #000000;
  text-transform: capitalize;
}
.tableContainer .container {
  width: auto !important;
  border-radius: 10px;
}
.tableContainer .container .table-responsive::-webkit-scrollbar {
  height: 10px;
}
.tableContainer .container .table-responsive::-webkit-scrollbar-track {
  background-color: hsla(0, 0%, 2%, 0);
}
.tableContainer .container .table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
}
.tableContainer .container .table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 60%);
}
.tableContainer .container .table-responsive .actionBtnContainer {
  margin-right: 10px;
}

.paddingHorizontal {
  padding: 0 15px;
}

.paddingTop {
  padding-top: 15px;
}

.megamenuItem {
  transition: 0.3s;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #004aad;
}
.megamenuItem p {
  font-size: 14px;
  color: #000000;
}
.megamenuItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.MuiFormControlLabel-labelPlacementEnd {
  margin: 0 !important;
  margin-bottom: 10px !important;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.197) !important;
}
.MuiFormControlLabel-labelPlacementEnd .MuiTypography-body1 {
  font-size: 18px;
  padding: 10px 0;
  margin-left: 10px;
}

.formLabel {
  font-size: 22px !important;
  color: #000000;
}

.form {
  display: grid;
  gap: 10px;
}
.form .button {
  justify-self: center;
}

.formRow {
  display: flex;
  flex-direction: column;
}

.addEducation .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addEducation .header button {
  margin-left: auto;
  font-size: 20px;
  padding: 10px;
}

.spinnerContainer .spinner {
  border: 5px solid #004aad;
  border-top: 5px solid rgb(255, 255, 255);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.8s linear infinite;
  margin: 20px auto;
  place-self: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 28px;
  height: 28px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #666666 transparent #666666 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingContainer {
  height: 500px;
  display: grid;
  place-items: center;
}

.button {
  border-radius: 15px;
  max-width: 250px;
  width: 100%;
  padding: 8px 10px;
  background-color: #004aad;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
}
.button p {
  font-size: 14px;
}
.button:hover {
  background-color: #004aad;
}

.jobApplyModal {
  padding-top: 20px;
  margin: 15px;
  height: 100vh;
  overflow: scroll;
  width: 100%;
  max-width: 500px;
  display: grid;
  place-items: center;
}
.jobApplyModal .content {
  padding: 20px;
  background-color: rgb(255, 255, 255);
  max-width: 900px;
  border-radius: 10px;
  width: 100%;
}
.jobApplyModal .form {
  display: grid;
  gap: 10px;
}
.jobApplyModal .form .button {
  justify-self: center;
}
.jobApplyModal .formRow {
  display: flex;
  flex-direction: column;
}

.jobApplyModal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.inputContainers {
  width: 100%;
}

.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;
  z-index: 900;
}
.navbarContainer .navbarSubContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbarContainer h5 {
  font-size: 12px;
  margin: 0;
  font-weight: 500;
}
.navbarContainer p {
  margin: 0;
}
.navbarContainer .menuModal {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100dvh;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  overflow: hidden;
}
.navbarContainer .menuModal .closeBtnContainer {
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  width: 100%;
}
.navbarContainer .menuModal .closeBtnContainer .closeBtn {
  padding: 15px;
  font-size: 24px;
}
.navbarContainer .menuModal .menuModalContent {
  height: -moz-fit-content;
  height: fit-content;
  max-height: 100%;
  display: grid;
  place-items: center;
  gap: 1px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.199);
}
.navbarContainer .navbarLogo {
  width: 120px;
  padding: 5px 0;
  justify-self: flex-start;
}
.navbarContainer .navbarContent {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.navbarContainer .navbarContent .bar {
  height: 30px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.4);
  margin-right: 20px;
}
.navbarContainer .navbarContent .verticalBar {
  height: 30px;
  width: 1px;
  background-color: #cfcfcf;
}
.navbarContainer .navbarContent .hamburgerMenu {
  padding: 10px;
  font-size: 25px;
  color: #fff;
  position: relative;
}
.navbarContainer .navbarContent .hamburgerMenuDark {
  color: black !important;
}
.navbarContainer .navbarContent .jobButton {
  height: 60px;
  padding: 10px 15px;
  border-bottom: 2px solid transparent;
  font-size: 20px;
  display: grid;
  place-items: center;
  position: relative;
}
.navbarContainer .navbarContent .jobButton:hover {
  background-color: rgba(122, 172, 252, 0.2);
}
.navbarContainer .navbarContent .jobButton .submenu {
  position: absolute;
  width: 200px;
  top: 55px;
  left: -100;
  z-index: 999;
  display: grid;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 5px;
  gap: 5px;
  overflow: hidden;
}
.navbarContainer .navbarContent .jobButton .submenuItem {
  transition: 0.3s;
}
.navbarContainer .navbarContent .jobButton .submenuItem p {
  font-size: 12px;
}
.navbarContainer .navbarContent .jobButton .submenuItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.navbarContainer .navbarContent .jobButton .megamenu {
  position: absolute;
  width: 600px;
  top: 55px;
  left: -300;
  z-index: 999;
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 5px;
  gap: 5px;
  overflow: hidden;
  padding: 20px 10px;
}
.navbarContainer .navbarContent .jobButton .megamenu .barContainer {
  padding: 20px 0;
  height: 100%;
}
.navbarContainer .navbarContent .jobButton .megamenu .barContainer .bar {
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2666666667);
}
.navbarContainer .navbarContent .jobButton .megamenu .megamenuSection {
  padding: 15px;
}
.navbarContainer .navbarContent .jobButton h4 {
  text-align: left;
}
.navbarContainer .navbarContent .jobButton .itemContainer {
  margin-top: 10px;
  display: grid;
  gap: 5px;
}
.navbarContainer .navbarContent .fontlight {
  color: #fff !important;
}
.navbarContainer .navbarContent .selected {
  border-bottom: 2px solid #004aad;
}

@media (max-width: 1150px) {
  .navbarContainer {
    padding: 0 0 0 15px;
  }
  .navbarContainer .navbarContent {
    gap: 5px;
  }
}
.navbarContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.navbarScrolled {
  background-color: rgb(255, 255, 255);
}

.navbatBtnContainer .navbarBtn {
  padding: 3px 7px;
  display: grid;
  place-items: center;
  background-color: #004aad;
  border-radius: 10px;
  color: #fff;
}
.navbatBtnContainer .navbarBtn p {
  font-size: 16px;
}
.navbatBtnContainer .navbarBtn:hover {
  background-color: #004aad;
}

.navbarBtnMobile {
  padding: 10px 20px;
  transition: 0.3s;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.loginInput {
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
  transition: 0.3s;
  border: 2px solid #dbe1e7;
}
.loginInput:focus {
  border: 2px solid #004aad;
}

.loginButton {
  background-color: #004aad;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
}
.loginButton:hover {
  background-color: #004aad;
}

.companyCardContainer {
  display: grid;
  place-items: center;
}
.companyCardContainer .imageContainer {
  max-width: 150px;
  border-radius: 5px;
  overflow: hidden;
}
.companyCardContainer .imageContainer img {
  max-width: 100%;
  max-height: 100%;
}
.companyCardContainer .textcontainer {
  overflow: hidden;
}
.companyCardContainer h4 {
  white-space: nowrap;
}

.resumeBox {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  padding: 10px 15px;
}
.resumeBox .textContainer {
  color: #3a3a3a;
  white-space: normal;
  width: 100%;
  overflow: hidden;
}
.resumeBox .textContainer h3 {
  font-size: 18px;
  margin-bottom: 5px;
  word-wrap: break-word;
}
.resumeBox .textContainer p {
  font-size: 14px;
}
@media (max-width: 500px) {
  .resumeBox .textContainer {
    max-width: 200px;
  }
}
.resumeBox .btnContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.resumeBox .btnContainer .viewBtn {
  cursor: pointer;
  font-size: 25px;
  padding: 0px 15px;
  background: #004aad;
  border-radius: 15px;
}
.resumeBox .btnContainer .viewBtn p {
  font-size: 13px;
  color: #fff !important;
}
.resumeBox .btnContainer .viewBtn:hover {
  background-color: #004aad;
}
.resumeBox .btnContainer .deleteBtn {
  cursor: pointer;
  font-size: 25px;
  padding: 8px;
  font-size: 14px;
  color: rgb(211, 0, 0);
}

.banner {
  margin-top: 60px;
  height: 280px;
  background: #004aad; /* fallback for old browsers */
  padding: 70px 0;
}
.banner .bannerContent {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 15px;
}
.banner .bannerContent h1 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}
.banner .bannerContent .imgContainer {
  max-width: 300px;
}
@media (max-width: 900px) {
  .banner .bannerContent {
    justify-content: center;
  }
  .banner .bannerContent .imgContainer {
    display: none;
  }
}

@media (max-width: 1150px) {
  .banner {
    margin-top: 45px;
  }
}
.expandBtn {
  background-color: #004aad;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #fff;
  display: grid;
  place-items: center;
}

.faqBtn {
  background-color: #fff;
  border-radius: 15px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
.faqBtn .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 15px;
}
.faqBtn .header p {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}
.faqBtn .header .secondary {
  border: 1px solid;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1px 10px;
  border-radius: 10px;
}
.faqBtn .textContent {
  display: grid;
  gap: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding: 20px;
}
.faqBtn .textContent p {
  text-align: left !important;
  font-size: 14px;
}
.faqBtn .textContent .ulList {
  padding-left: 15px;
}

.footer {
  background-color: #004aad;
  padding: 40px 15px 15px 15px;
  overflow: hidden;
}
.footer .color1 {
  color: #fff;
}
.footer .color2 {
  color: rgba(255, 255, 255, 0.7);
}
.footer .iconRow {
  display: flex;
  gap: 10px;
  align-items: center;
}
.footer .mainContent {
  display: grid;
  gap: 25px;
  font-size: 12px;
}
.footer .mainContent h4 {
  margin-bottom: 15px !important;
}
.footer .mainContent p {
  font-size: 12px;
}
.footer .mapContainer {
  border-radius: 10px;
  overflow: hidden;
  border: 8px solid rgb(68, 90, 163);
  background-color: #000000;
}
.footer .bottomContainer {
  margin-top: 20px;
  border-top: 2px solid rgba(255, 255, 255, 0.4156862745);
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
}
.footer .bottomContainer p {
  font-size: 12px;
  color: #ffffff;
}
.footer .bottomContainer .socialContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}
.footer .bottomContainer .socialContainer .socialBtn {
  color: #fff;
  font-size: 20px;
}
.footer .bottomContainer .socialContainer .bar {
  height: 15px;
  width: 1px;
  background-color: #fff;
}
@media (max-width: 400px) {
  .footer .bottomContainer {
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
  }
}

.authContainer {
  padding: 100px 15px;
  background-color: #f8fafb;
  min-height: 70dvh;
  min-height: 70vh;
  display: grid;
  place-items: center;
}
.authContainer .authContent {
  margin: 0 auto;
  display: grid;
  place-items: center;
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
}
.authContainer .authContent .authMainContent {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
}
.authContainer .authContent .authMainContent .ptag1 {
  text-align: center;
  padding: 18px 0;
  border-bottom: 1px solid #dbe1e7;
}
.authContainer .authContent .authMainContent .typeContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px 15px;
}
.authContainer .authContent .authMainContent .authBtnsContainer {
  padding: 15px;
  padding-top: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
.authContainer .authContent .authMainContent .authBtnsContainer .authBtns {
  position: relative;
  display: grid;
  place-items: center;
  padding: 15px 0;
  border: 1px solid #dbe1e7;
  border-radius: 10px;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}
.authContainer .authContent .authMainContent .authBtnsContainer .authBtns h4 {
  color: rgba(0, 0, 0, 0.7019607843);
  font-size: 16px;
  display: none;
}
.authContainer .authContent .authMainContent .authBtnsContainer .authBtns .iconContainer {
  display: grid;
  place-items: center;
  font-size: 22px;
}
.authContainer .authContent .authMainContent .authBtnsContainer .authBtns:hover {
  border: 1px solid #004aad;
  background-color: rgba(122, 172, 252, 0.2);
}
.authContainer .authContent .authMainContent .seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}
.authContainer .authContent .authMainContent .seperator .seperatorLine {
  width: 100%;
  height: 0.8px;
  background-color: #cfcfcf;
}
.authContainer .authContent .authMainContent .loginBox {
  padding: 0 15px;
}
.authContainer .authContent .authMainContent .loginBox .loginButton {
  margin-top: 20px;
  width: 100%;
}
.authContainer .authContent .authMainContent .loginBox .inputFieldContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.authContainer .authContent .authMainContent .loginBox .inputFieldContainer h4 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.authContainer .authContent .authMainContent .loginBox .submitBtnContainer {
  display: grid;
  place-items: center;
  padding: 15px 0;
}
@media (max-width: 850px) {
  .authContainer .authContent {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 550px) {
  .authContainer .authContent .inputFieldContainer {
    grid-template-columns: 1fr !important;
  }
}

.profileContainer {
  min-height: 100vh;
  min-height: 100dvh;
  padding: 60px 15px 100px 15px;
}
.profileCompletion {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.profileCompletionLine {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
}

.profileCompletionFill {
  height: 100%;
  background-color: #61c261;
  transition: width 0.3s ease;
}

.profileCompletionDescription {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}
            
.kycBanner {
  margin-top: 20px;
  height: 280px;
  background: #004aad;
  padding: 70px 0;
  transition: all 0.3s ease;
}
.kycBannerContent {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 15px;
}

.kycBannerContent h1 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}
.kycDescription {
  width: 70%;
  display: flex;
  margin-left: 190px;
  margin-top: 40px;
  justify-content: center;
  text-align: center;
}
.kycDescription p {
  text-align: justify;
  padding: 0 2px; 
}
.kycParagraph {
  font-size: 18px;
  color: #333333;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: justify;
  padding: 0 2px;
  margin-left: 10px;
  margin-right: 10px;
}
.kycDropdownContainer {
  text-align: center;
  margin-top: 20px;
}

.kycDropdown {
  padding: 2%;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #cccccc;
}
.kycCenterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
  margin-bottom: 3%;
  flex-direction: column;
  margin: 2%;
}

.Kyc_sel_input{
  padding: 2%;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #cccccc;
}

.label_01{
  width: auto;
  white-space: nowrap;
  margin-right: 2%;
  margin: 2%;
}

.file-kyc{
  display: flex;
  flex-direction: row;
}

.KYC-id-sel{
  display: flex;
  flex-direction: row;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  
}

.buttonContainer button {
  margin: 0 5px;
}

.otherDocumentContainer input {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  width: 10px;
  margin-top: 10px;
  display: flex;
  flex: 1;
}
.otherDocumentContainer {
  display: flex;
  align-items: center;
}
.documentNumberContainer {
  display: flex;
  align-items: center;
}
.idTypeContainer{
  display: flex;
  align-items: center;
}
.documentNumberContainer input{
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  width: 380px;
  margin-top: 10px;
  display: flex;
  flex: 1;
}
.customButton {
  border-radius: 15px;
  padding: 15px 20px;
  letter-spacing: 1px;
  font-size: 12px;
  margin-top: 12px;
  margin-right: 10px;
  margin-left: 20px;
  cursor: pointer;

}
.customButtonFilled {
  border: 2px solid #004aad;
  background-color: #004aad;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.customButtonFilled:hover {
  border: 2px solid #004aad;
  background-color: #004aad;
}

.Toastify__progress-bar {
  background: #044cac; /* Set a solid background color */
  background-size: 100%;
}






.kycButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #61c261;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.kycButton span {
  margin-right: 4px;
}

.kycButton:hover {
  background-color: #3e8e3e;
}

.profileContainer .profileHeaderContainer {
  margin: 0 auto;
  max-width: 1000px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 50px;
  padding: 10px 10px 0 10px;
}
.profileContainer .profileHeaderContainer .profileMainSection {
  padding: 10px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 35px;
}
.profileContainer .profileHeaderContainer .profileMainSection .imageContainer {
  height: 200px;
  width: 200px;
  border-radius: 8px;
  background-color: #000000;
}
.profileContainer .profileHeaderContainer .profileMainSection .imageContainer img {
  width: 100%;
  height: 100%;
}
.profileContainer .profileHeaderContainer .profileMainSection .textContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  gap: 10px;
}
.profileContainer .profileHeaderContainer .profileMainSection .textContainer .mainTextContent {
  overflow: scroll;
}
.profileContainer .profileHeaderContainer .profileMainSection .textContainer .mainTextContent::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.profileContainer .profileHeaderContainer .profileMainSection .textContainer .details {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  text-align: left;
  gap: 10px;
  margin-top: 20px;
}
.profileContainer .profileHeaderContainer .profileMainSection .textContainer .buttonContainer {
  display: grid;
  grid-template-columns: 1fr;
  height: -moz-fit-content;
  height: fit-content;
  gap: 20px;
}
.profileContainer .profileHeaderContainer .profileMainSection .textContainer button {
  font-size: 20px;
  padding: 5px;
}
.profileContainer .profileHeaderContainer .profileMainSection .textContainer h2 {
  color: #000000;
}
.profileContainer .profileHeaderContainer .profileMainSection .textContainer h4 {
  color: rgba(0, 0, 0, 0.5960784314);
}

.lab-kyc{
  margin-top: 4%;
  margin: 5%;
  margin-left: 6%;
}

.lab-kyc-doc{
  margin-top: 4%;
  margin-left: 0%;
  margin-right: 1%;
}


.inputDoc{
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  height: 35px;
  margin-top: 5%;
  width: 100%;
}

@media (max-width: 700px) {
  .profileContainer .profileHeaderContainer .profileMainSection {
    grid-template-columns: 1fr;
  }
  .profileContainer .profileHeaderContainer .profileMainSection .imageContainer {
    place-self: center;
  }

  .customButtonFilled {
    border: 2px solid #004aad;
    background-color: #004aad;
    color: #fff;
  }

  .Kyc_sel_input{
    padding: 2%;
    font-size: 13px;
    border-radius: 4px;
    border: 1px solid #cccccc;
  }

  .kycDescription {
    width: 70%;
    display: flex;
    margin-left: 15%;
    margin-top: 5%;
    text-align: center;
    justify-content: center;
  }
  .label_01{
    width: auto;
    white-space: nowrap;
    margin-right: 2%;
  }

  .kycCenterContainer {
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 4%;
    flex-direction: column;
  
  }

  .inputDoc{
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #cccccc;
    height: 35px;
    margin-top: 0%;
    margin-bottom: 3%;
  }

  
.lab-kyc-doc{
  margin-top: 5%;
  margin-bottom: 0% ;
  margin-left: 0%;
}

  .file-kyc{
    display: flex;
    flex-direction: column;
  }
  
}
.profileContainer .profileHeaderContainer .profileNavbarContainer {
  display: flex;
  overflow: scroll;
  width: 100%;
  gap: 10px;
  white-space: nowrap;
}
.profileContainer .profileHeaderContainer .profileNavbarContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.profileContainer .profileHeaderContainer .profileNavbarContainer .navText {
  color: #666666;
  border-bottom: 2px solid transparent;
  padding: 15px 10px;
  font-weight: 600;
}
.profileContainer .profileHeaderContainer .profileNavbarContainer .navText:hover,
.profileContainer .profileHeaderContainer .profileNavbarContainer .active {
  color: #004aad;
  border-bottom: 2px solid #004aad;
}
.profileContainer .profileContent {
  margin: 0 auto;
  max-width: 1000px;
}
.profileContainer .profileContent .profileBanner {
  margin: 40px 0;
}
.profileContainer .profileContent .profileBanner h1 {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.7529411765);
}
.profileContainer .profileContent .profileMainContainer {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}
.profileContainer .profileContent .profileMainContainer .tableContainer {
  overflow: scroll;
}
.profileContainer .profileContent .profileMainContainer .tableContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.profileContainer .profileContent .profileMainContainer .tableContainer .tableBody {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: rgba(0, 0, 0, 0.1215686275);
  width: 100% !important;
}
.profileContainer .profileContent .profileMainContainer .addEmployerContainer .inputFieldContainer {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.profileContainer .profileContent .profileMainContainer .addEmployerContainer .inputFieldContainer h4 {
  color: #000000;
}
@media (max-width: 500px) {
  .profileContainer .profileContent .profileMainContainer .addEmployerContainer .inputFieldContainer {
    grid-template-columns: 1fr;
  }
}
.profileContainer .profileContent .profileMainContainer .addEmployerContainer .submitBtnContainer {
  display: grid;
  place-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer {
  padding: 10px;
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .heroboxContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 40px;
  box-sizing: border-box;
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .heroboxContainer .herobox {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid rgba(0, 1, 78, 0.1647058824);
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .heroboxContainer .herobox p {
  font-size: 14px;
  color: hsla(0, 0%, 0%, 0.8);
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .heroboxContainer .herobox h1 {
  color: #000000;
  font-size: 24px;
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .heroboxContainer .herobox h2 {
  color: #000000;
  font-size: 15px;
}
@media (max-width: 850px) {
  .profileContainer .profileContent .profileMainContainer .currentPlanContainer .heroboxContainer {
    grid-template-columns: 1fr;
  }
  .profileContainer .profileContent .profileMainContainer .currentPlanContainer .heroboxContainer .herobox {
    padding: 10px 15px;
  }
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .permissionTableContainer p {
  font-size: 18px;
  color: hsla(0, 0%, 0%, 0.8);
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .permissionTableContainer .rowContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 100%;
  margin-top: 10px;
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .permissionTableContainer .rowContainer .card {
  align-items: center;
  margin: 0 auto;
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: auto 1fr;
  border-radius: 10px;
  padding: 10px 10px;
  border: 1px solid rgba(0, 1, 78, 0.1647058824);
  background-color: #fff;
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .permissionTableContainer .rowContainer .card .icon {
  font-size: 20px;
  display: grid;
  place-items: center;
  color: rgba(0, 0, 0, 0.8156862745);
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .permissionTableContainer .rowContainer .card .icon .iconContent {
  height: 40px;
  width: 40px;
  background-color: #004aad;
  border-radius: 50%;
  color: #fff;
  display: grid;
  place-items: center;
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .permissionTableContainer .rowContainer .card .header {
  font-weight: 600;
  font-size: 16px;
}
.profileContainer .profileContent .profileMainContainer .currentPlanContainer .permissionTableContainer .rowContainer .card .desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6117647059);
  line-height: 18px;
}
@media (max-width: 750px) {
  .profileContainer .profileContent .profileMainContainer .currentPlanContainer .permissionTableContainer .rowContainer {
    grid-template-columns: 1fr;
  }

  
}
.profileContainer .profileContent .profileMainContainer .apiKeyContainer {
  padding: 10px;
  display: grid;
  gap: 15px;
}
.profileContainer .profileContent .profileMainContainer .apiKeyContainer .urlContainer {
  margin-top: 5px;
  background-color: #fff;
  padding: 3px 10px;
  border-radius: 6px;
  border: 1px solid #dbe1e7;
  word-break: break-all;
}
.profileContainer .profileContent .profileMainContainer .employeeDataContainer {
  padding: 10px;
}
.profileContainer .profileContent .profileMainContainer .employeeDataContainer .educationCardContainer {
  padding: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
}
.profileContainer .profileContent .profileMainContainer .employeeDataContainer .educationCardContainer p {
  padding-top: 5px;
}
.profileContainer .profileContent .profileMainContainer .employeeDataContainer .educationCardContainer .editBtn {
  padding: 10px;
  font-size: 18px;
}
.profileContainer .profileContent .profileMainContainer .employeeDataContainer .educationCardContainer .editBtn2 {
  padding: 10px;
  font-size: 18px;
  color: rgb(218, 0, 0);
}
.profileContainer .profileContent .profileMainContainer .employeeDataContainer .experiencesCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  .profileContainer .profileContent .profileMainContainer .employeeDataContainer .experiencesCardContainer {
    grid-template-columns: 1fr;
  }
}
.profileContainer .profileContent .profileMainContainer .employeeDataContainer .skillsCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}
@media (max-width: 750px) {
  .profileContainer .profileContent .profileMainContainer .employeeDataContainer .skillsCardContainer {
    grid-template-columns: 1fr 1fr;
  }
 
}
@media (max-width: 520px) {
  .profileContainer .profileContent .profileMainContainer .employeeDataContainer .skillsCardContainer {
    grid-template-columns: 1fr;
  }
}
.profileContainer .profileContent .profileMainContainer .employeeDataContainer .resumeContainer {
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card1Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
}
@media (max-width: 700px) {
  .profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card1Container {
    grid-template-columns: 1fr 1fr;
  }
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card2Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
@media (max-width: 800px) {
  .profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card2Container {
    grid-template-columns: 1fr;
  }
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card1 {
  padding: 25px;
  border-radius: 10px;
  background-color: #fff;
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card1 .iconContainer {
  font-size: 30px;
  color: #7c7c7c;
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card1 .value {
  padding-top: 40px;
  font-size: 34px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7607843137);
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card1 .desc {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6117647059);
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card2 {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card2 .title1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card2 .title2 {
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6352941176);
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .card2 .rowContainer {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .row1 {
  background-color: #fff;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 0;
  gap: 20px;
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .row1 .value {
  font-size: 18px;
  text-transform: capitalize;
}
.profileContainer .profileContent .profileMainContainer .employerOverviewContainer .row1:last-child {
  border-bottom: none;
}
.profileContainer .profileContent .employerContainer {
  margin: 20px 0;
  display: flex;
  gap: 10px;
}
.profileContainer .profileContent .warningText {
  padding-top: 5px;
  padding-bottom: 20px;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
}
.profileContainer .profileContent .editContainer {
  background-color: #fff;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  text-align: left;
  gap: 15px;
  padding: 20px;
  border-radius: 15px;
  margin-top: 20px;
  position: relative;
  color: #3a3a3a;
  width: 100%;
}
.profileContainer .profileContent .editContainer .rightIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: grid;
  place-items: center;
  padding-right: 20px;
}
.profileContainer .profileContent .formContainer {
  height: 100%;
  overflow-y: scroll;
  max-height: 80vh;
}

.editProfileContainer {
  padding: 100px 15px 60px 15px;
  min-height: 60vh;
}
.editProfileContainer .editProfileContent {
  margin: 0 auto;
  max-width: 600px;
}
.editProfileContainer .editProfileContent .editProfileHeader .backBtn {
  font-size: 24px;
  padding: 5px 5px 5px 0;
  margin-bottom: 20px;
}
.editProfileContainer .editProfileContent .editProfileFields {
  margin-top: 30px;
  display: grid;
  gap: 15px;
}
.editProfileContainer .editProfileContent .editProfileFields h4 {
  font-size: 16px;
  color: #000000;
}

.homeContainer .heroContainer {
  background-image: url(../img/slider_bg.jpg);
  background-size: cover !important;
  background-position: center !important;
  background-color: black;
  height: 100dvh;
  height: 100vh;
  display: grid;
  align-items: center;
}
.homeContainer .heroContainer .heroSection {
  background-color: rgba(0, 0, 0, 0.4);
  display: grid;
  align-items: center;
  padding: 20px;
  height: 100dvh;
  height: 100vh;
}
.homeContainer .heroContainer .heroSection .heroContent {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.homeContainer .heroContainer .heroSection .heroContent .heroTitle {
  max-width: 700px;
}
.homeContainer .heroContainer .heroSection .heroContent .heroTitle h2 {
  font-size: 45px !important;
  color: #ffffff;
}
@media (max-width: 1000px) {
  .homeContainer .heroContainer .heroSection .heroContent .heroTitle h2 {
    font-size: 40px !important;
  }
}
.homeContainer .heroContainer .heroSection .heroContent .heroTitle .highlight {
  color: #00ec57;
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer {
  border-radius: 30px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 40px;
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar {
  width: 100%;
  border-radius: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  border: 8px solid rgba(0, 0, 0, 0.1);
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar .form-control {
  margin: 0 !important;
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar .bar {
  height: 30px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.5);
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar .input1 {
  width: 300px;
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar .input2 {
  max-width: 200px;
  margin-right: 10px;
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar .searchInput {
  width: 100%;
  height: 100%;
  padding: 5px 10px 5px 20px;
  outline: none;
  border: none;
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar .submitBtnContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px;
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar .submitBtnContainer p {
  font-size: 14px;
  font-weight: 600;
}
@media (max-width: 700px) {
  .homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar .submitBtnContainer p {
    font-size: 16px;
  }
}
.homeContainer .heroContainer .heroSection .heroContent .searchbarContainer .searchbar .submitBtnContainer .iconContainer {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: grid;
  place-items: center;
  background-color: #004aad;
  color: #fff;
  font-size: 14px;
}
.homeContainer .categoryCardContainer {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: stretch;
  gap: 30px;
}
@media (max-width: 1000px) {
  .homeContainer .categoryCardContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 750px) {
  .homeContainer .categoryCardContainer {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .homeContainer .categoryCardContainer {
    grid-template-columns: 1fr;
  }
}
.homeContainer .jobcardsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: stretch;
  gap: 30px;
  padding: 15px 0;
}
@media (max-width: 850px) {
  .homeContainer .jobcardsContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 650px) {
  .homeContainer .jobcardsContainer {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .homeContainer .jobcardsContainer {
    grid-template-columns: 1fr;
  }
}
.homeContainer .jobSection {
  background-color: #004aad;
}
.homeContainer .sections {
  padding: 50px 20px;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
}
.homeContainer .sections .sectionsContainer {
  display: grid;
  gap: 20px;
}
.homeContainer .sections .subSection .subSectionContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 50px;
}
.homeContainer .sections .subSection .subSectionContent .imgContainer {
  display: grid;
  place-items: center;
  max-width: 500px;
  width: 100%;
}
.homeContainer .sections .subSection .subSectionContent .headingContainer {
  margin-bottom: 20px;
}
.homeContainer .sections .subSection .subSectionContent .textContainer {
  max-width: 500px;
}
.homeContainer .sections .subSection .oppositeDirection {
  flex-direction: row-reverse;
}
@media (max-width: 800px) {
  .homeContainer .sections .subSection .subSectionContent {
    flex-direction: column;
  }
}
.homeContainer .sections .subSection .horizontalBar {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 80px 0;
}
.homeContainer .sections .sectionHeading {
  text-align: center;
  margin-top: 15px;
}

@media (max-width: 500px) {
  .homeContainer .container {
    margin-top: 50px;
  }
}
.resumeUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  padding-top: 80px;
  min-height: 60vh;
}



.resumeContainerUpload {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
  padding-bottom: 30px;
}
.resumeContainerUpload .resumeCardContainer {
  margin-top: 20px;
  display: grid;
  gap: 10px;
}
.resumeContainerUpload h1 {
  color: rgba(0, 0, 0, 0.568627451);
  font-size: 18px;
  padding-top: 10px;
}

.jobPageContainer {
  padding-top: 75px;
}
.jobPageContainer .matchContainer {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 15px 20px;
  border: 2px solid #004aad;
  text-align: center;
}
.jobPageContainer .matchContainer p {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
  color: #004aad;
}
.jobPageContainer .detailListContainer .listItem {
  padding: 7px 5px;
  display: flex;
  align-items: center;
  width: 100%;
  word-wrap: break-word;
  border-bottom: 1px dashed #eaeff5;
  white-space: initial;
}
.jobPageContainer ul {
  padding-left: 20px;
  margin: 0;
}

.searchPage {
  display: grid;
  place-items: center;
  padding-top: 75px;
}
.searchPage section {
  width: 100%;
}
.searchPage .filterContainer {
  background-color: #fff;
  width: 100%;
}
.searchPage .filterContainer .filterContent {
  display: flex;
  
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1225px;
  margin: 0 auto;
  padding: 0 10px;
}
.searchPage .filterContainer .filterContent .filterBtn {
  background-color: #e4e2e0;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  transition: 0.3s;
}
.searchPage .filterContainer .filterContent .filterBtn p {
  margin-right: 10px;
}
.searchPage .filterContainer .filterContent .filterBtn:hover {
  background-color: #cdcbca;
}
.searchPage .filterContainer .filterFieldsChosenContainer {
  max-width: 1225px;
  margin: 0 auto;
  padding: 0 10px;
  padding-top: 30px;
}
.searchPage .filterContainer .filterFieldsChosenContainer .filterFieldsChosenContent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}
.searchPage .filterContainer .filterFieldsChosenContainer .filterFieldsChosenContent .filterFieldsChosen {
  border-radius: 10px;
  border: 1px solid #e4e2e0;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.searchPage .filterContainer .filterFieldsChosenContainer .filterFieldsChosenContent .filterFieldsChosen:hover {
  background-color: #efeeec;
}

.dashboardContainer {
  padding-top: 60px;
  display: flex;
  flex-direction: row;
}
.dashboardContainer .navBar {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #c7c7c7;
}
.dashboardContainer .navBar .navButtons {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.dashboardContainer .navBar .navButtons p {
  font-size: 16px;
}
.dashboardContainer .navBar .navButtons button {
  text-align: left;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  color: #3a3a3a;
  display: grid;
  place-items: center;
  grid-template-columns: 35px 1fr;
  grid-gap: 10px;
}
.dashboardContainer .navBar .navButtons button:hover {
  color: #fff;
  background-color: #004aad;
}
.dashboardContainer .navBar .navButtons button p {
  font-size: 14px;
}
.dashboardContainer .navBar .navButtons .selected {
  color: #fff;
  background-color: #004aad;
}
@media (max-width: 900px) {
  .dashboardContainer .navBar {
    padding: 20px 10px;
  }
  .dashboardContainer .navBar .navButtons {
    place-items: center;
    grid-gap: 40px;
  }
  .dashboardContainer .navBar .navButtons button {
    font-size: 16px;
    grid-gap: 0px;
    padding: 10px 0px;
  }
  .dashboardContainer .navBar .navButtons button p {
    display: none;
  }
}
.dashboardContainer .dashboardContent {
  width: 100%;
  min-height: 70vh;
  padding: 20px 0;
}
.dashboardContainer .dashboardContent .dashboardHeader {
  padding-left: 10px;
}

@media (max-width: 500px) {
  .dashboardContainer {
    position: relative;
  }
  .dashboardContainer .navBar {
    position: absolute;
    width: 100%;
    top: 75;
    padding: 10px;
    overflow-x: scroll;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .dashboardContainer .navBar .navHeader {
    display: none;
  }
  .dashboardContainer .navBar .navButtons {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
  }
  .dashboardContainer .navBar .navButtons button {
    grid-gap: 0px;
    font-size: 16px;
    padding: 12px 4px;
  }
  .dashboardContainer .navBar .navButtons button p {
    display: none;
  }
  .dashboardContainer .dashboardContent {
    padding-top: 68px;
  }
}
.jobAppliedContainer .jobAppliedContent {
  margin: 0 auto;
  display: grid;
  gap: 20px;
  place-items: center;
  padding: 0 15px;
}
.jobAppliedContainer .jobAppliedContent .searchbarContainer {
  width: 100%;
  padding-top: 20px;
  max-width: 600px;
}

.allJobsContainer {
  padding: 80px 15px 20px 15px;
  min-height: 60vh;
}
.allJobsContainer .searchcontainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 0;
}
.allJobsContainer .mainContent {
  display: grid;
  grid-template-columns: 280px 1fr;
  max-width: 1000px;
  margin-left:  0 auto;
  gap: 20px;
}
@media (max-width: 700px) {
  .allJobsContainer .mainContent {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 700px) {
  .allJobsContainer {
    padding-top: 30px;
  }
  .allJobsContainer .filterContainer {
    display: flex;
    overflow: scroll;
    gap: 10px;
  }
  .allJobsContainer .filterContainer .filterBtn {
    background: #ffffff;
    border-radius: 10px;
    padding: 8px 16px;
    border: 1px solid #dbe1e7;
  }
}
.filterModalContainer {
  display: grid;
  gap: 15px;
}
.filterModalContainer .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.filterModalContainer .header button {
  padding: 5px;
  font-size: 16px;
}

.companyPageContainer {
  padding: 0px 15px 50px 15px;
}
.companyPageContainer .companiesPageContent {
  max-width: 1225px;
  margin: 0 auto;
}
.companyPageContainer .companiesPageContent .content1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}
.companyPageContainer .companiesPageContent .content1 .textContainer {
  max-width: 500px;
  width: 100%;
}
.companyPageContainer .companiesPageContent .content1 .textContainer p {
  font-size: 14px;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.5529411765);
}
.companyPageContainer .companiesPageContent .content1 .imgContainer {
  max-width: 500px;
  width: 100%;
}
@media (max-width: 800px) {
  .companyPageContainer .companiesPageContent .content1 {
    flex-direction: column;
  }
}
.companyPageContainer .companiesPageContent .companiesCardContainer {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr;
  width: 100%;
  gap: 20px;
  margin-top: 100px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 80px 15px;
  border-radius: 10px;
}
.companyPageContainer .companiesPageContent .companiesCardContainer .subContainer {
  display: grid;
  place-items: center;
  gap: 40px;
}
.companyPageContainer .companiesPageContent .companiesCardContainer .bar {
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.3);
}
.companyPageContainer .companiesPageContent .companiesCardContainer .horiontalBar {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
}
.companyPageContainer .companiesPageContent .companiesCardContainer .spanentire {
  grid-column: 1/-1;
  margin: 20px 0;
}
@media (max-width: 750px) {
  .companyPageContainer .companiesPageContent .companiesCardContainer {
    grid-template-columns: 1fr auto 1fr;
  }
}

.categoryPageContainer {
  padding: 100px 15px 50px 15px;
}
.categoryPageContainer .categoryPageContent {
  max-width: 1225px;
  margin: 0 auto;
}
.categoryPageContainer .categoryPageContent .categoryCardContainer {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: stretch;
  gap: 30px;
}
@media (max-width: 1000px) {
  .categoryPageContainer .categoryPageContent .categoryCardContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 750px) {
  .categoryPageContainer .categoryPageContent .categoryCardContainer {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .categoryPageContainer .categoryPageContent .categoryCardContainer {
    grid-template-columns: 1fr;
  }
}

.contactContainer {
  display: grid;
  place-items: center;
}
.contactContainer .contactContent {
  width: 100%;
}
.contactContainer .contactContent .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 60px;
  padding: 0 15px 50px 15px;
  max-width: 1000px;
  margin: 0 auto;
}
.contactContainer .contactContent .header .imageContainer {
  max-width: 600px;
}
.contactContainer .contactContent .header .textContainer {
  display: grid;
  gap: 15px;
}
.contactContainer .contactContent .header .textContainer .mainText {
  color: rgba(0, 0, 0, 0.699);
  line-height: 20px;
  max-width: 500px;
}
.contactContainer .contactContent .header .textContainer .list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}
.contactContainer .contactContent .header .textContainer .list p {
  font-size: 14px;
}
@media (max-width: 800px) {
  .contactContainer .contactContent .header {
    flex-direction: column-reverse;
  }
}
.contactContainer .contactContent .mainContent {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 50px 15px;
  overflow: hidden;
}
.contactContainer .contactContent .mainContent .contentSubHeading {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
.contactContainer .contactContent .mainContent .contentSubHeading p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
}
.contactContainer .contactContent .mainContent .mainSection {
  display: grid;
  grid-template-columns: 60% 40%;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  gap: 25px;
}
.contactContainer .contactContent .mainContent .mainSection .contactform {
  width: 100%;
  max-width: 500px;
}
.contactContainer .contactContent .mainContent .mainSection .contactform .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.contactContainer .contactContent .mainContent .mainSection .socialsContainer .contactRowContainer {
  display: grid;
  gap: 20px;
  margin-bottom: 30px;
}
.contactContainer .contactContent .mainContent .mainSection .socialsContainer .contactRowContainer .contactRow {
  display: flex;
  gap: 15px;
  align-items: center;
}
.contactContainer .contactContent .mainContent .mainSection .socialsContainer .contactRowContainer .contactRow .primary {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.contactContainer .contactContent .mainContent .mainSection .socialsContainer .contactRowContainer .contactRow .secondary {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.contactContainer .contactContent .mainContent .mainSection .socialsContainer .socialBtnContainer {
  margin-top: 20px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}
.contactContainer .contactContent .mainContent .mainSection .socialsContainer .btnContainer {
  background-color: #fff;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  border: 2px solid #004aad;
  transition: 0.3s;
  display: grid;
  place-items: center;
  font-size: 18px !important;
  cursor: pointer;
  color: #004aad;
}
.contactContainer .contactContent .mainContent .mainSection .socialsContainer .btnContainer:hover {
  border: 3px solid #004aad;
  color: #004aad;
}
@media (max-width: 700px) {
  .contactContainer .contactContent .mainContent .mainSection {
    grid-template-columns: 1fr;
    place-items: center;
    gap: 30px;
  }
}

.aboutusContainer {
  padding: 0px 15px 30px 15px;
  display: grid;
  place-items: center;
}
.aboutusContainer .aboutusContent {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.aboutusContainer .aboutusContent .imageContainer {
  width: 100%;
  display: grid;
  place-items: center;
}
.aboutusContainer .aboutusContent .imageContainer img {
  max-width: 500px;
}
.aboutusContainer .aboutusContent h2 {
  padding: 30px 0;
}
.aboutusContainer .aboutusContent .desc2 {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  place-items: center;
  margin: 20px 0;
}
.aboutusContainer .aboutusContent .desc2 .imgContainer img {
  max-width: 450px;
}
.aboutusContainer .aboutusContent .desc2 .textContainer {
  max-width: 550px;
}
.aboutusContainer .aboutusContent .desc2 .textContainer h3 {
  padding-bottom: 30px;
  font-size: 24px;
  color: #000000;
}
.aboutusContainer .aboutusContent .desc2 .textContainer p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}
.aboutusContainer .aboutusContent .reverse {
  flex-direction: row-reverse;
}
@media (max-width: 900px) {
  .aboutusContainer .aboutusContent .desc2 {
    flex-direction: column;
  }
  .aboutusContainer .aboutusContent .desc2 .textContainer {
    width: auto;
  }
  .aboutusContainer .aboutusContent .desc2 div {
    width: auto;
  }
}
.aboutusContainer .aboutusContent .cardsContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}
.aboutusContainer .aboutusContent .cardsContainer .cardContainer {
  max-width: 280px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: block;
  border: 1px solid #dbe1e7;
}
.aboutusContainer .aboutusContent .cardsContainer .cardContainer .imgContainer {
  margin: 0 auto;
  width: 250px;
}
.aboutusContainer .aboutusContent .cardsContainer .cardContainer h4 {
  color: #004aad;
}
.aboutusContainer .aboutusContent .cardsContainer .cardContainer p {
  padding-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #4c4c4c;
}
.aboutusContainer .sections {
  padding: 50px 0;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}
.aboutusContainer .sections .sectionsContainer {
  display: grid;
  gap: 40px;
}
.aboutusContainer .sections .subSection h4 {
  padding-bottom: 40px;
}
.aboutusContainer .sections .subSection .subSectionContent {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 40px;
}
.aboutusContainer .sections .subSection .subSectionContent .imgContainer {
  display: grid;
  place-items: center;
  min-width: 300px;
  max-width: 300px;
  min-height: 300px;
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
}
.aboutusContainer .sections .subSection .subSectionContent p {
  font-size: 16px;
}
.aboutusContainer .sections .subSection .subSectionContent ul {
  padding-top: 15px;
  padding-left: 15px;
}
.aboutusContainer .sections .subSection .oppositeDirection {
  flex-direction: row-reverse;
}
@media (max-width: 800px) {
  .aboutusContainer .sections .subSection .subSectionContent {
    flex-direction: column;
  }
}
.aboutusContainer .button {
  font-size: 14px;
  margin: 0 auto;
  margin-top: 30px;
}

.plansPageContainer {
  padding: 0px 15px 50px 15px;
}
.plansPageContainer .plansPageContent {
  max-width: 1225px;
  margin: 0 auto;
}
.plansPageContainer .plansPageContent .toggleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}
.plansPageContainer .plansPageContent .toggleContainer p {
  font-size: 18px;
}
.plansPageContainer .plansPageContent .toggleContainer button {
  font-size: 30px;
  color: #004aad;
}
.plansPageContainer .plansPageContent .pricingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  padding: 0 15px;
  margin: 0 auto;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard {
  background-color: #fff;
  border-radius: 15px;
  max-width: 300px;
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .header {
  background-color: rgb(28, 0, 98);
  text-align: center;
  padding: 14px;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .header .priceTitle {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .header .priceSubheader {
  font-size: 12px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8352941176);
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .priceLine {
  align-items: flex-end;
  margin: 10px 0px;
  display: grid;
  place-items: center;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .priceLine .price {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 2px;
  padding-right: 5px;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .priceLine .priceDetails {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7019607843);
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .priceBtnContainer {
  padding: 15px;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .priceBtnContainer .priceBtn {
  border-radius: 10px;
  width: 100%;
  padding: 10px 5px;
  color: #fff;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .line {
  margin: 25px 0px;
  background-color: rgba(0, 0, 0, 0.3803921569);
  height: 1px;
  width: 100%;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .priceRowContainer {
  padding: 15px;
  display: grid;
  place-items: center;
  height: 100%;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .priceRowContainer .pointsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .priceRowContainer .pointsContainer .points {
  display: grid;
  gap: 6px;
}
.plansPageContainer .plansPageContent .pricingContainer .pricingCard .priceRowContainer .pointsContainer .points .pricingRow {
  display: flex;
  align-items: center;
  gap: 10px;
}
.plansPageContainer .plansPageContent .pricingContainer .specialPricingCard {
  height: 450px;
}
.plansPageContainer .plansPageContent .pricingContainer .card1 .header {
  background-color: #00aa39;
}
.plansPageContainer .plansPageContent .pricingContainer .card1 .priceBtn {
  background-color: #00aa39;
}
.plansPageContainer .plansPageContent .pricingContainer .card1 .icon {
  color: #00aa39;
}
.plansPageContainer .plansPageContent .pricingContainer .card2 .header {
  background-color: #2a2c92;
}
.plansPageContainer .plansPageContent .pricingContainer .card2 .priceBtn {
  background-color: #2a2c92;
}
.plansPageContainer .plansPageContent .pricingContainer .card2 .icon {
  color: #2a2c92;
}
.plansPageContainer .plansPageContent .pricingContainer .card3 .header {
  background-color: #f47400;
}
.plansPageContainer .plansPageContent .pricingContainer .card3 .priceBtn {
  background-color: #f47400;
}
.plansPageContainer .plansPageContent .pricingContainer .card3 .icon {
  color: #f47400;
}

.jobcandidatesContainer {
  padding: 100px 15px 50px 15px;
  display: grid;
  place-items: center;
}
.jobcandidatesContainer .content {
  max-width: 1200px;
  width: 100%;
  overflow-x: auto;
  min-height: 60vh;
}
.jobcandidatesContainer .content .candidateList {
  margin-top: 50px;
}
.jobcandidatesContainer .content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.candicateCardModal h3 {
  padding-bottom: 20px;
}
.candicateCardModal li {
  font-size: 20px;
}
.candicateCardModal .responseContainer {
  padding-left: 20px;
  display: grid;
  gap: 10px;
}

.uploadFilesContainer {
  place-self: flex-start;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.uploadFilesContainer h3 {
  padding-bottom: 30px;
}
.uploadFilesContainer .cardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.uploadFilesContainer .videoCard {
  background-color: "#fff";
  display: flex;
  justify-content: "space-between";
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
}
.uploadFilesContainer .videoCard h4 {
  width: 100%;
}
.uploadFilesContainer .videoCard .btnContainer {
  display: flex;
  gap: 10px;
}

.kbPageContainer {
  padding: 100px 15px 50px 15px;
}
.kbPageContainer .kbPageContent {
  max-width: 1225px;
  margin: 0 auto;
  min-height: 60vh;
}
.kbPageContainer .kbPageContent .btnContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 600px;
  margin: 0 auto;
  gap: 20px;
}
.kbPageContainer .kbPageContent .btnContainer .btn {
  background: #fff;
  border-radius: 15px;
  display: grid;
  place-items: center;
  width: 100%;
  padding: 30px 15px;
  font-size: 26px;
}
.kbPageContainer .kbPageContent .btnContainer .btn p {
  font-size: 18px;
  padding-top: 10px;
  font-weight: 600;
}
.kbPageContainer .kbPageContent .btnContainer .btn:hover {
  background-color: rgba(0, 0, 0, 0.062745098);
}

.devPageContainer {
  padding: 50px 15px;
}
.devPageContainer .devPageContent {
  max-width: 1225px;
  margin: 0 auto;
  min-height: 60vh;
}
.devPageContainer .devPageContent h1 {
  padding: 50px 0;
  text-align: center;
}

.faqPageContainer {
  padding: 50px 15px;
}
.faqPageContainer .faqPageContent {
  max-width: 1225px;
  margin: 0 auto;
}
.faqPageContainer .faqPageContent h2 {
  text-align: center;
  padding: 50px 0;
}
.faqPageContainer .faqPageContent .faqBtnContainer {
  display: grid;
  gap: 20px;
}

.policyPageContainer {
  padding: 100px 15px;
}
.policyPageContainer .policyPageContent {
  display: grid;
  gap: 30px;
  max-width: 900px;
  margin: 0 auto;
  text-align: justify;
}
.policyPageContainer .policyPageContent h1 {
  font-size: 28px;
  padding-bottom: 10px;
  text-align: center;
}
.policyPageContainer .policyPageContent h2 {
  font-size: 22px;
  padding: 25px 0;
}
.policyPageContainer .policyPageContent h3 {
  font-size: 18px;
  padding: 15px 0;
}
.policyPageContainer .policyPageContent ul {
  padding-left: 22px;
}

.parsedContainer::-webkit-scrollbar {
  width: 12px;
}
.parsedContainer::-webkit-scrollbar-track {
  background: hsla(0, 0%, 0%, 0);
}
.parsedContainer::-webkit-scrollbar-thumb {
  background-color: rgb(138, 138, 138);
  border-radius: 25px;
  border: 2px solid rgb(255, 255, 255);
  -webkit-transition: 3s;
  transition: 3s;
}
.parsedContainer::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 60%);
}
.parsedContainer .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parsedContainer .header button {
  margin-left: auto;
  font-size: 20px;
  padding: 10px;
}
.parsedContainer .subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parsedContainer .deleteButton {
  padding: 10px;
  color: red;
}
.parsedContainer h4 {
  padding-bottom: 20px;
}
.parsedContainer .button {
  margin: 0 auto;
}
.parsedContainer .skillsContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.parsedContainer .skillsContainer .skillAddbutton {
  padding: 5px 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  border: 2px solid #fff;
}
.parsedContainer .skillsContainer .selected {
  border: 2px solid #004aad;
  color: #004aad;
}
.parsedContainer .skillsContainer .selected p {
  font-weight: 600 !important;
}

.verifyContainer {
  min-height: 80vh;
  display: grid;
  place-items: center;
}
.verifyContainer .verifyContent {
  display: grid;
  place-items: center;
}
.verifyContainer .verifyContent .iconContainer {
  font-size: 60px;
  color: #004aad;
}
.verifyContainer .verifyContent h3 {
  padding-top: 30px;
}
.verifyContainer .verifyContent p {
  padding-top: 10px;
  padding-bottom: 5px;
}

.deiPageContainer {
  padding: 100px 15px 50px 15px;
  min-height: 100vh;
}
.deiPageContainer .deiPageContent {
  max-width: 1225px;
  margin: 0 auto;
}
.deiPageContainer .deiPageContent .textContainer {
  display: grid;
  gap: 30px;
}
.deiPageContainer .deiPageContent .textContainer .subSection .subSectionContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 50px;
}
.deiPageContainer .deiPageContent .textContainer .subSection .subSectionContent .imgContainer {
  display: grid;
  place-items: center;
  max-width: 400px;
  width: 100%;
}
.deiPageContainer .deiPageContent .textContainer .subSection .subSectionContent .textContainer {
  max-width: 600px;
}
.deiPageContainer .deiPageContent .textContainer .subSection .subSectionContent .headingContainer {
  margin-bottom: 20px;
}
.deiPageContainer .deiPageContent .textContainer .subSection .oppositeDirection {
  flex-direction: row-reverse;
}
@media (max-width: 800px) {
  .deiPageContainer .deiPageContent .textContainer .subSection .subSectionContent {
    flex-direction: column;
  }
}
.deiPageContainer .deiPageContent .textContainer .subSection .horizontalBar {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 80px 0;
}
.deiPageContainer .deiPageContent .raceorientationContainer {
  width: 500px;
  display: grid;
  place-items: center;
  gap: 15px;
  padding-top: 80px;
  margin: 0 auto;
}
.deiPageContainer .deiPageContent .raceorientationContainer div {
  width: 100%;
}
.deiPageContainer .deiPageContent .raceorientationContainer h4 {
  color: #000000;
}
.deiPageContainer .deiPageContent .quizContainer {
  min-height: 60dvh;
  min-height: 60vh;
  display: grid;
  place-items: center;
}
.deiPageContainer .deiPageContent .quizContainer .quizNotStart {
  display: grid;
  place-items: center;
  gap: 30px;
}
.deiPageContainer .deiPageContent .quizContainer .quizStart {
  display: grid;
  max-width: 550px;
  gap: 30px;
}
.deiPageContainer .deiPageContent .quizContainer .quizStart .nextPrevBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.candidateSearchContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
}
.candidateSearchContainer .searchContainer {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 20px;
}
.candidateSearchContainer .candidateSearchContent {
  
  grid-template-columns: 300px 1fr;
  gap: 20px;
  padding-top: 20px;
}
.candidateSearchContainer .candidateSearchContent .mainContent {
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  gap: 15px;
}
@media (max-width: 1200px) {
  .candidateSearchContainer .candidateSearchContent {
    grid-template-columns: 200px 1fr;
  }
}
@media (max-width: 700px) {
  .candidateSearchContainer .candidateSearchContent {
    grid-template-columns: 1fr;
  }
}

.jobSearchContainer {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 15px;
}
.jobSearchContainer .searchContainer {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 20px;
}
.jobSearchContainer .candidateSearchContent {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 20px;
  padding-top: 20px;
}
.jobSearchContainer .candidateSearchContent .mainContent {
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  gap: 15px;
}
@media (max-width: 1200px) {
  .jobSearchContainer .candidateSearchContent {
    grid-template-columns: 200px 1fr;
  }
}



@media (max-width: 700px) {
  .jobSearchContainer .candidateSearchContent {
    grid-template-columns: 1fr;
  }
}

.deiStatsPageContainer {
  padding: 0px 0px 50px 0px;
}
.deiStatsPageContainer .deiStatsPageContent {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}
.deiStatsPageContainer .deiStatsPageContent .statsContainer {
  padding: 50px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.deiStatsPageContainer .deiStatsPageContent .statsContainer2 {
  padding: 50px 15px 0 15px;
  display: flex;
  flex-direction: row;
  gap: 15px;
}
@media (max-width: 800px) {
  .deiStatsPageContainer .deiStatsPageContent .statsContainer2 {
    flex-direction: column;
  }
}

.employerPageContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  padding-bottom: 50px;
}

.apidocsContainer {
  padding-top: 60px;
  height: 100dvh;
  height: 100vh;
}
.apidocsContainer *::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.apidocsContainer .sidebarOpenBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 15px;
}
.apidocsContainer .sidebarCloseBtn {
  padding: 15px;
  font-size: 20px;
}
.apidocsContainer .apidocsContent {
  display: grid;
  grid-template-columns: 300px 1fr;
  overflow: scroll;
  height: 100%;
}
.apidocsContainer .sidebar {
  background-color: #fff !important;
  overflow: scroll;
}
.apidocsContainer .sidebar h4 {
  color: rgb(96, 96, 96);
  padding-left: 10px;
  padding-bottom: 5px;
}
.apidocsContainer .ps-menu-button {
  height: 40px !important;
}
.apidocsContainer .sidebarItem {
  padding: 0 !important;
}
.apidocsContainer .sidebarItemSelected {
  border-right: 4px solid #004aad;
  background-color: rgba(85, 150, 255, 0.1);
  color: #004aad;
  font-weight: 600;
}
.apidocsContainer .menuBtnContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.apidocsContainer .typeContainer {
  place-self: flex-end;
  align-self: center;
  padding: 2px 8px;
  border-radius: 15px;
  background-color: #fff;
}
.apidocsContainer .typeContainer P {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
}
.apidocsContainer .getrequest {
  background-color: green;
  color: rgb(255, 255, 255);
}
.apidocsContainer .postrequest {
  background-color: #e2bc15;
  color: rgb(255, 255, 255);
}
.apidocsContainer .patchrequest {
  background-color: orange;
  color: rgb(255, 255, 255);
}
.apidocsContainer .deleterequest {
  background-color: red;
  color: rgb(255, 255, 255);
}
.apidocsContainer .homeContainer {
  padding: 20px;
}
.apidocsContainer .homeContainer .header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.apidocsContainer .homeContainer .header .desc {
  padding-top: 6px;
}
.apidocsContainer .homeContainer .section {
  display: grid;
  gap: 8px;
  padding: 30px 0;
}
.apidocsContainer .homeContainer .section .urlContainer {
  background-color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 10px;
  border-radius: 6px;
  border: 1px solid #dbe1e7;
}
.apidocsContainer .mainContentContainer {
  overflow: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.apidocsContainer .mainContentContainer .textContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 15px;
}
.apidocsContainer .mainContentContainer .textContainer h4 {
  font-size: 16px;
  color: black;
}
.apidocsContainer .mainContentContainer .textContainer .header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.apidocsContainer .mainContentContainer .textContainer .desc {
  padding: 10px 0 5px 0;
}
.apidocsContainer .mainContentContainer .textContainer .urlContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.apidocsContainer .mainContentContainer .textContainer .urlContainer .url {
  font-size: 20px;
}
.apidocsContainer .mainContentContainer .textContainer .paramsContainer {
  display: grid;
  gap: 5px;
  margin-top: 10px;
}
.apidocsContainer .mainContentContainer .textContainer .paramsContainer .paramsCard {
  display: grid;
  grid-template-columns: 30% 60%;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #dbe1e7;
}
.apidocsContainer .mainContentContainer .exampleContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  background-color: #2f3336;
}
.apidocsContainer .mainContentContainer .exampleContainer h4 {
  color: #fff;
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
}
.apidocsContainer .mainContentContainer .exampleContainer .codeContainer {
  background-color: #1e2224;
  color: #fff;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
}
.apidocsContainer .mainContentContainer .exampleContainer pre {
  border: none;
}
@media (max-width: 950px) {
  .apidocsContainer .mainContentContainer {
    grid-template-columns: 1fr;
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media (max-width: 600px) {
  .apidocsContainer .apidocsContent {
    grid-template-columns: 1fr;
  }
  .apidocsContainer .sidebar {
    position: fixed;
    width: 70vw;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    padding-left: 0px;
  }
}

@media (max-width: 600px) {
  .apidocsContainer {
    height: 100%;
  }
}
.slotsPageContainer {
  min-height: 60vh;
}
.slotsPageContainer .slotsPageContent {
  padding: 40px 15px;
  max-width: 900px;
  margin: 0 auto;
}
.slotsPageContainer .slotsPageContent .form {
  max-width: 500px;
  margin: 0 auto;
}
.slotsPageContainer .slotsPageContent .slotsContainer {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.slotsPageContainer .slotsPageContent .slotsContainer .candidateSlotCard {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
}
@media (max-width: 500px) {
  .slotsPageContainer .slotsPageContent .slotsContainer {
    grid-template-columns: 1fr;
  }
}

.slotsCandidatePageContainer {
  min-height: 60vh;
}
.slotsCandidatePageContainer .slotsPageContent {
  padding: 40px 15px;
  max-width: 900px;
  margin: 0 auto;
}
.slotsCandidatePageContainer .slotsPageContent .form {
  max-width: 500px;
  margin: 0 auto;
}
.slotsCandidatePageContainer .slotsPageContent .slotsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
.slotsCandidatePageContainer .slotsPageContent .slotsContainer .candidateSlotCard {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  gap: 20px;
}
.slotsCandidatePageContainer .slotsPageContent .slotsContainer .candidateSlotCard .candidateDetails {
  padding: 10px 20px;
  width: 100%;
  display: grid;
  gap: 5px;
  border-left: 1px solid #dbe1e7;
}
.slotsCandidatePageContainer .slotsPageContent .slotsContainer .candidateSlotCard .candidateDetails .rowContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 500px) {
  .slotsCandidatePageContainer .slotsPageContent .slotsContainer {
    grid-template-columns: 1fr;
  }
  .slotsCandidatePageContainer .slotsPageContent .slotsContainer .candidateSlotCard {
    grid-template-columns: 1fr;
    gap: 0px;
  }
  .slotsCandidatePageContainer .slotsPageContent .slotsContainer .candidateSlotCard .candidateDetails {
    border-top: 1px solid #dbe1e7;
    border-left: none;
  }
}


.countdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other content */
}

.countdown-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 24px;
}

