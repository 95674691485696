@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: #f8fafb;
  color: #000000;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

a {
  background-color: transparent;
  background-color: transparent;
  text-decoration: none;
  color: #000000;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0 !important;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

img {
  width: 100%;
  height: auto;
}

button {
 
  cursor: pointer;
  transition: 0.3s;
}
button:hover {
  transform: scale(1.05);
}

input {
  transition: 0.3s;
  border: none;
  outline: none;
}

.resumeSearchCard {
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:700px;
  display: flex;
}
.resumeSearchCard .header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.resumeSearchCard .header .location {
  text-transform: capitalize;
}
.resumeSearchCard .header h4 {
  font-size: 18px;
}
.resumeSearchCard h5 {
  padding: 10px 0;
}
.resumeSearchCard .actionBtnContainer {
  padding: 5px 15px;
  font-size: 14px;
}